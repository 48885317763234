import createClient from "openapi-fetch";
import type { paths } from "~/openapi/schema";
import { isServer } from "~/lib/isServer";
import { isProduction } from "~/lib/isProduction";
import { hasMediaOptimizedFormats } from "~/openapi/hasMediaOptimizedFormats";

export class StrapiRepository {
  private readonly strapiClient;

  constructor() {
    const strapiUrl = isServer
      ? process.env.STRAPI_INTERNAL_URL
      : window.ENV.STRAPI_PUBLIC_URL;
    if (!strapiUrl) {
      throw new Error("Strapi url is not defined");
    }
    this.strapiClient = createClient<paths>({
      baseUrl: `${strapiUrl}/api`,
    });
  }

  getMediaUrl = (filePath?: string) => {
    if (!filePath) {
      return "";
    }

    if (isProduction) {
      return filePath;
    }

    const strapiPublicUrl = isServer
      ? process.env.STRAPI_PUBLIC_URL
      : window.ENV.STRAPI_PUBLIC_URL;

    return `${strapiPublicUrl}${filePath}`;
  };

  getImageUrl = (
    image: Parameters<typeof hasMediaOptimizedFormats>[0],
    preferredFormat: "large" | "medium" | "small" | "thumbnail" = "large",
  ) => {
    const hasOptimizedFormats = hasMediaOptimizedFormats(image);

    if (hasOptimizedFormats) {
      return this.getMediaUrl(image.formats[preferredFormat].url);
    }

    return this.getMediaUrl(image.url);
  };

  getNavbarItems = async () => {
    return this.strapiClient.GET("/navbar", {
      params: {
        query: {
          // @ts-ignore
          "populate[nav_items][populate]": "*",
          "populate[logo][populate]": "*",
        },
      },
    });
  };

  getHomepage = async () => {
    return this.strapiClient.GET("/homepage", {
      params: {
        query: {
          // @ts-ignore
          "populate[content][populate]": "*",
          "populate[hero_section][populate]": "*",
        },
      },
    });
  };

  getReferencesBySlug = async (referenceSlug: string) => {
    return this.strapiClient.GET("/references/slug/{slug}", {
      params: {
        path: {
          slug: referenceSlug,
        },
        // @ts-ignore
        query: {
          "populate[seo][populate]": "*",
        },
      },
    });
  };
}
